import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  
  // Base URL setup from environment variable
  const defaultBaseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
  const [baseUrl] = useState(defaultBaseUrl);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get(`${baseUrl}/me`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data);  // Ensure proper user data
        }
      } catch (error) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  }, [baseUrl]); 

  const signIn = async (username, password) => {
    try {
      const response = await axios.post(`${baseUrl}/sign-in/`, { username, password });
      const { access_token } = response.data;

      // Store the token locally
      localStorage.setItem('token', access_token);

      // Fetch user info after successful login
      const userInfo = await axios.get(`${baseUrl}/me`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });

      setUser(userInfo.data);
      return true; // Sign-in success
    } catch (error) {
      console.error('Login failed', error);
      throw new Error('Invalid credentials');
    }
  };

  const signOut = () => {
    localStorage.removeItem('token'); // Clear token from local storage
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loading, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
