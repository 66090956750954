import React, { useState, useEffect } from 'react';
import './Menu.css';
import { NavLink } from 'react-router-dom';
import menu from './img/menu.svg';

const Menu = ({ searchTerm, handleSearch }) => {
  const [menuOpen, setMenuOpen] = useState(false); // Default to closed on mobile
  const [isMobile, setIsMobile] = useState(false);

  // Check screen size to toggle mobile view
  useEffect(() => {
    const checkMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
        setMenuOpen(false); // Default to closed on mobile
      } else {
        setIsMobile(false);
        setMenuOpen(true); // Default to open on larger screens
      }
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const menuLinks = [
    { to: '/genai', title: 'Generative AI', description: 'Have a conversation with Hubbiot AI' },
    { to: '/auth', title: 'Auth', description: 'Manage authentication and authorization' },
    { to: '/graph', title: 'Graph Engine', description: 'Explore data in the Hubbiot Graph Engine' },
    { to: '/ar', title: 'Augmented Reality', description: 'Connect the data with the world around you.' },
    { to: '/configurator', title: 'Graph Configurator', description: 'Configure and model your data landscape.' },
    { to: '/api', title: 'API Documentation', description: 'Explore Hubbiot APIs' },
  ];

  return (
    <>
      {/* Floating button for both larger and smaller screens */}
      {!menuOpen && (
        <div className="menu-toggle-icon" onClick={() => setMenuOpen(true)}>
          <img src={menu} alt="Menu" className="menu" />
        </div>
      )}

      {/* Menu container */}
      <div className={`menu-container ${menuOpen ? 'open' : ''}`}>
        {(menuOpen || !isMobile) && (
          <div className="menu-content">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search tools..."
                value={searchTerm}
                onChange={handleSearch}
                className="search-input"
              />
            </div>
            <div className="menu-links">
              {menuLinks
                .filter(link => link.title.toLowerCase().includes(searchTerm))
                .map((link, index) => (
                  <NavLink className="menu-link" to={link.to} key={index} onClick={() => isMobile && setMenuOpen(false)}>
                    <div>
                      <strong>{link.title}</strong>
                      <p>{link.description}</p>
                    </div>
                  </NavLink>
                ))}
            </div>
            {/* Collapse Menu Text */}
            {menuOpen && (
              <div className="collapse-menu" onClick={() => setMenuOpen(false)}>
                Collapse Menu &lt;&lt;
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Menu;
