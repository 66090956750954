import React from 'react';
import './Footer.css';
import logo from './img/logo.svg'; // Importing the logo

const Footer = () => {
  return (
    <footer className="footer">
      <img src={logo} alt="Logo" className="footer-logo" />
      <p>© 2024 Hubbiot AB</p>
      <p>v.0.4.5</p>
    </footer>
  );
};

export default Footer;
