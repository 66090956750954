// GraphPage.js
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Graph3DGlow from './Graph3DGlow';
import Graph3DSpace from './Graph3DSpace';
import Graph2D from './Graph2D';
import './GraphPage.css';

const GraphPage = () => {
  const containerRef1 = useRef();
  const containerRef2 = useRef();
  const containerRef3 = useRef();

  const [graphData1, setGraphData1] = useState(null);
  const [graphData2, setGraphData2] = useState(null);
  const [graphData3, setGraphData3] = useState(null);
  const [direction, setDirection] = useState('outbound'); // Selector for direction
  const [maxDepth, setMaxDepth] = useState(3); // Selector for max_depth

  const defaultBaseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
  const [baseUrl] = useState(defaultBaseUrl);

  // Function to fetch graph info and traversal data
  const fetchGraphData = async () => {
    try {
      const graphInfoResponse = await axios.get(`${baseUrl}/graph_info/`);
      const graphInfo = graphInfoResponse.data;

      const groupName = 'machine';
      const groupNodesResponse = await axios.get(`${baseUrl}/groups/${groupName}/nodes`);
      const nodes = groupNodesResponse.data.nodes;

      if (nodes.length === 0) {
        throw new Error(`No nodes found in group: ${groupName}`);
      }

      const startNodeId = nodes[0]._id;

      const traverseResponse = await axios.get(`${baseUrl}/graph/traverse/`, {
        params: {
          start_node_id: startNodeId,
          max_depth: maxDepth,
          direction: direction
        }
      });
      const traversalData = traverseResponse.data;

      setGraphData1(JSON.parse(JSON.stringify(traversalData))); // For Graph3DGlow
      setGraphData2(JSON.parse(JSON.stringify(traversalData))); // For Graph3DSpace
      setGraphData3(JSON.parse(JSON.stringify(traversalData))); // For Graph2D

    } catch (error) {
      console.error('Error fetching graph data:', error);
    }
  };

  // Fetch graph data when component mounts or when direction/maxDepth changes
  useEffect(() => {
    fetchGraphData();
  }, [baseUrl, direction, maxDepth]);

  return (
    <div className="graph-page-container">
      <Header />
      
      {/* Container for controls */}
      <div className="controls-container">
        <div className="selector">
          <label htmlFor="direction">Direction:</label>
          <select id="direction" value={direction} onChange={(e) => setDirection(e.target.value)}>
            <option value="any">Any</option>
            <option value="inbound">Inbound</option>
            <option value="outbound">Outbound</option>
          </select>
        </div>

        <div className="selector">
          <label htmlFor="max_depth">Max Depth:</label>
          <select id="max_depth" value={maxDepth} onChange={(e) => setMaxDepth(Number(e.target.value))}>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
          </select>
        </div>
      </div>

      {/* Graph containers */}
      {/* <div id="graph-container1" ref={containerRef1} className="graph-box">
        {graphData1 ? (
          <Graph3DGlow data={graphData1} containerRef={containerRef1} />
        ) : (
          <p>Loading Graph 1...</p>
        )}
      </div> */}

      <div id="graph-container2" ref={containerRef2} className="graph-box">
        {graphData2 ? (
          <Graph3DSpace data={graphData2} containerRef={containerRef2} />
        ) : (
          <p>Loading Graph 2...</p>
        )}
      </div>

      <div id="graph-container3" ref={containerRef3} className="graph-box">
        {graphData3 ? (
          <Graph2D data={graphData3} containerRef={containerRef3} />
        ) : (
          <p>Loading Graph 3...</p>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default GraphPage;
