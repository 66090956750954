import React, { useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import './HomePage.css';

// Register the necessary chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const HomePage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [menuOpen, setMenuOpen] = useState(true); // Added state for menu open/close

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Data for Widget 1 (Line chart for server compute)
  const serverComputeData = {
    labels: ['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
    datasets: [
      {
        label: 'Server Compute',
        data: [10, 20, 40, 45, 50, 55, 70, 80, 90, 100],
        borderColor: '#079fac',
        backgroundColor: 'rgba(7, 159, 172, 0.2)',
        tension: 0.4,
      },
    ],
  };

  // Data for Widget 2 (Bar chart for increasing nodes)
  const nodeGrowthData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'],
    datasets: [
      {
        label: 'Nodes in Graph DB',
        data: [200, 350, 450, 600, 750, 900, 1050, 1200],
        backgroundColor: '#079fac',
      },
    ],
  };

  // Data for Widget 3 (Graph summary)
  const graphOverview = {
    nodeGroups: 12,
    relationships: 45,
  };

  return (
    <div className={`homepage-layout ${menuOpen ? 'menu-open' : 'menu-closed'}`}>
      <Header />

      {/* Main content section with the menu and the main content */}
      <div className="content-layout">
        {/* Left-side menu */}
        <Menu searchTerm={searchTerm} handleSearch={handleSearch} setMenuOpen={setMenuOpen} menuOpen={menuOpen} />

        {/* Main content area */}
        <div className="main-content">
          <div className="tba-container">
            {/* Widget 1: Line Chart showing server compute */}
            <div className="tba-widget">
              <Line data={serverComputeData} />
            </div>

            {/* Widget 2: Bar chart showing node growth */}
            <div className="tba-widget">
              <Bar data={nodeGrowthData} />
            </div>

            {/* Widget 3: Graph overview summary */}
            <div className="tba-widget">
              <div>
                <h3>Graph Overview</h3>
                <p>Node Groups: {graphOverview.nodeGroups}</p>
                <p>Node Relationships: {graphOverview.relationships}</p>
              </div>
            </div>

            {/* Widget 4: Custom content (API usage summary) */}
            <div className="tba-widget">
              <div>
                <h3>API Usage</h3>
                <p>Total API calls: 5,000</p>
                <p>Average response time: 120ms</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
