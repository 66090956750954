import React, { useState, useRef } from 'react';
import Header from '../../components/Header'; // Import Header component
import Footer from '../../components/Footer'; // Import Footer component
import './GenAIPage.css';

// Use environment variable or fallback to defaultBaseUrl
const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';

const GenAIPage = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isSending, setIsSending] = useState(false);
  const chatBoxRef = useRef(null);

  // Use the baseUrl in the API URL
  const apiUrl = `${baseUrl}/stream-genai/`;

  const sendMessage = async () => {
    if (input.trim() === '') return;

    const userMessage = { sender: 'User', text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setIsSending(true);

    const data = { prompt: input };
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let aiMessage = { sender: 'AI', text: '' };
        setMessages((prevMessages) => [...prevMessages, aiMessage]);

        try {
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              console.log("Stream finished.");
              break;
            }

            if (value) {
              const chunk = decoder.decode(value, { stream: true });
              setMessages((prevMessages) =>
                prevMessages.map((message, idx) =>
                  idx === prevMessages.length - 1
                    ? { ...message, text: message.text + chunk }
                    : message
                )
              );
              scrollToBottom();
            }
          }
        } catch (error) {
          console.error('Error in reading stream:', error);
          setMessages((prevMessages) =>
            prevMessages.map((message, idx) =>
              idx === prevMessages.length - 1
                ? { ...message, text: message.text + "\n[Error occurred in the stream]" }
                : message
            )
          );
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'System', text: 'Failed to fetch response from AI.' },
      ]);
    } finally {
      setIsSending(false);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  return (
    <div className="genai-page-container">
      <Header /> {/* Add the Header component */}
      <div id="chat-container" className="chat-container">
        <div id="chat-box" ref={chatBoxRef} className="chat-box">
          {messages.map((message, index) => (
            <p key={index} className={message.sender === 'AI' ? 'ai-message' : 'user-message'}>
              {message.sender}: {message.text}
            </p>
          ))}
        </div>
        <input
          type="text"
          id="user-input"
          value={input}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Type your message here..."
          className="user-input"
          disabled={isSending}
        />
        <button id="send-button" onClick={sendMessage} disabled={isSending} className="send-button">
          {isSending ? 'Sending...' : 'Send'}
        </button>
      </div>
      <Footer /> {/* Add the Footer component */}
    </div>
  );
};

export default GenAIPage;
