import React from 'react';
import Header from './Header';

const AppLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="app-content">
        {children} {/* Render child components */}
      </div>
    </>
  );
};

export default AppLayout;
