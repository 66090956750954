

import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu'; // Importing the Menu component
import { QRCodeCanvas } from 'qrcode.react'; // Import the QRCodeCanvas for generating QR codes
import './GraphConfiguratorPage.css';

const defaultBaseUrl = 'http://localhost:8000'; // Default backend URL

const GraphConfiguratorPage = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const [newGroup, setNewGroup] = useState({
    group_name: '',
    data_schema: [
      { name: '_id', data_type: 'string', read_only: true }, // Predefined field (non-deletable)
      { name: 'created_at', data_type: 'string', read_only: true } // Predefined field (non-deletable)
    ]
  });

  const [newRelationship, setNewRelationship] = useState({ relationship_type: '', from_group: '', to_group: '', unique: false });
  const [newNode, setNewNode] = useState({});
  const [allGroups, setAllGroups] = useState([]); // Initialize as an array
  const [allRelationships, setAllRelationships] = useState([]); // To store relationships (edges)
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupMessage, setGroupMessage] = useState('');
  const [relationshipTypeMessage, setRelationshipTypeMessage] = useState('');
  const [relationshipMessage, setRelationshipMessage] = useState('');
  const [nodeMessage, setNodeMessage] = useState('');
  const [isGroupsExpanded, setIsGroupsExpanded] = useState(false); // State to toggle groups expandable container
  const [isRelationshipsExpanded, setIsRelationshipsExpanded] = useState(false); // State to toggle relationships expandable container
  const [expandedGroups, setExpandedGroups] = useState([]); // To track which groups are expanded
  const [expandedRelationships, setExpandedRelationships] = useState([]); // To track which relationships are expanded
  const [qrCodeData, setQRCodeData] = useState(null); // To hold QR code data from response

  const [fromNodes, setFromNodes] = useState([]);
  const [toNodes, setToNodes] = useState([]);
  const [selectedFromNode, setSelectedFromNode] = useState('');
  const [selectedToNode, setSelectedToNode] = useState('');

  const containerRef = useRef();
  const qrCanvasRef = useRef(); // To refer to the QR code canvas for downloading the QR code

  const defaultBaseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
  const [baseUrl, setBaseUrl] = useState(defaultBaseUrl);

  useEffect(() => {
    setBaseUrl(defaultBaseUrl);
    fetchGraphInfo(); // Fetch both groups and relationships on load
  }, []);

  // API endpoints
  const graphInfoEndpoint = `${baseUrl}/graph_info/`;
  const getGroupNodesEndpoint = `${baseUrl}/groups/`;
  const createGroupEndpoint = `${baseUrl}/create_node_group/`;
  const createRelationshipTypeEndpoint = `${baseUrl}/create_relationship_type/`;
  const createNodeEndpoint = `${baseUrl}/create_node/`;
  const createRelationshipEndpoint = `${baseUrl}/create_relationship/`;

  // Fetch all groups and relationships from the API
  const fetchGraphInfo = async () => {
    try {
      const response = await fetch(graphInfoEndpoint);
      const data = await response.json();
      if (response.ok && data.groups && data.relationships) {
        setAllGroups(data.groups);
        setAllRelationships(data.relationships);
      } else {
        setGroupMessage('Error fetching graph information');
      }
    } catch (error) {
      setGroupMessage('Error: ' + error.message);
    }
  };

  const fetchGroupNodes = async (groupName) => {
    try {
      const response = await fetch(`${getGroupNodesEndpoint}${groupName}/nodes`);
      const data = await response.json();
      return data.nodes || [];
    } catch (error) {
      console.error('Error fetching group nodes:', error);
      return [];
    }
  };

  const addSchemaField = () => {
    setNewGroup({
      ...newGroup,
      data_schema: [...newGroup.data_schema, { name: '', data_type: 'string', read_only: false }] // Add new field with default values
    });
  };

  const removeSchemaField = (index) => {
    if (newGroup.data_schema[index].name === '_id' || newGroup.data_schema[index].name === 'created_at') return;
    const updatedSchema = newGroup.data_schema.filter((_, i) => i !== index);
    setNewGroup({ ...newGroup, data_schema: updatedSchema });
  };

  const handleSchemaChange = (index, field, value) => {
    const updatedSchema = newGroup.data_schema.map((schemaField, i) =>
      i === index ? { ...schemaField, [field]: value } : schemaField
    );
    setNewGroup({ ...newGroup, data_schema: updatedSchema });
  };

  const createGroup = async () => {
    try {
      const response = await fetch(createGroupEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          group_name: newGroup.group_name,
          data_schema: newGroup.data_schema.filter((field) => field.name) // Only submit fields with a name
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setGroupMessage(data.message);
        setNewGroup({
          group_name: '',
          data_schema: [
            { name: '_id', data_type: 'string', read_only: true },
            { name: 'created_at', data_type: 'string', read_only: true }
          ]
        });
        fetchGraphInfo(); // Refresh groups and relationships after creation
      } else {
        setGroupMessage(data.detail || 'Error creating group');
      }
    } catch (error) {
      setGroupMessage('Error: ' + error.message);
    }
  };

  const createRelationshipType = async () => {
    try {
      const response = await fetch(createRelationshipTypeEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          relationship_name: newRelationship.relationship_type,
          from_group: [newRelationship.from_group],
          to_group: [newRelationship.to_group],
          unique: newRelationship.unique
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setRelationshipTypeMessage(data.message);
        setNewRelationship({ relationship_type: '', from_group: '', to_group: '', unique: false });
        fetchGraphInfo(); // Refresh groups and relationships after creation
      } else {
        setRelationshipTypeMessage(data.detail || 'Error creating relationship type');
      }
    } catch (error) {
      setRelationshipTypeMessage('Error: ' + error.message);
    }
  };

  const handleRelationshipSelection = async (relationshipType) => {
    const relationship = allRelationships.find(r => r.relationship_name === relationshipType);
    if (relationship) {
      const fromNodes = await fetchGroupNodes(relationship.from_groups[0]);
      const toNodes = await fetchGroupNodes(relationship.to_groups[0]);
      setFromNodes(fromNodes);
      setToNodes(toNodes);
      setNewRelationship({ ...newRelationship, relationship_type: relationshipType });
    }
  };

  const createRelationship = async () => {
    try {
      const response = await fetch(createRelationshipEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from_node_id: selectedFromNode,
          to_node_id: selectedToNode,
          relationship_type: newRelationship.relationship_type
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setRelationshipMessage(data.message);
        setSelectedFromNode('');
        setSelectedToNode('');
        fetchGraphInfo(); // Refresh groups and relationships after creation
      } else {
        setRelationshipMessage(data.detail || 'Error creating relationship');
      }
    } catch (error) {
      setRelationshipMessage('Error: ' + error.message);
    }
  };

  const handleNodeDataChange = (e, field) => {
    const fieldSchema = selectedGroup.data_schema.find(f => f.name === field);
    if (fieldSchema.data_type === 'number') {
      const value = e.target.value;
      const isValidNumber = /^-?\d*(\.\d*)?$/.test(value);
      if (isValidNumber || value === '') {
        setNewNode({ ...newNode, [field]: value === '' ? '' : parseFloat(value) });
      }
    } else {
      setNewNode({ ...newNode, [field]: e.target.value });
    }
  };

  const createNode = async () => {
    try {
      const filteredNodeData = Object.fromEntries(
        Object.entries(newNode).filter(([key]) => {
          const field = selectedGroup.data_schema.find(field => field.name === key);
          return field && !field.read_only;
        })
      );

      const response = await fetch(createNodeEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          group_name: selectedGroup.group_name,
          node_data: filteredNodeData,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setNodeMessage('Node created successfully');
        setQRCodeData(data.details); // Set the QR code data from the response
        setNewNode({});
      } else {
        setNodeMessage('Error creating node');
      }
    } catch (error) {
      setNodeMessage('Error: ' + error.message);
    }
  };

  const downloadQRCode = () => {
    const qrCanvas = qrCanvasRef.current.querySelector('canvas');
    const qrURL = qrCanvas.toDataURL('image/png');

    const fileName = qrCodeData && qrCodeData._id ? `qr_${qrCodeData._id}.png` : 'qr-code.png';

    const a = document.createElement('a');
    a.href = qrURL;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleNumberInputKeyDown = (e) => {
    if (['e', 'E', '+', '-', '.'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const toggleGroupsExpand = () => {
    setIsGroupsExpanded(!isGroupsExpanded);
  };

  const toggleRelationshipsExpand = () => {
    setIsRelationshipsExpanded(!isRelationshipsExpanded);
  };

  const toggleGroup = (index) => {
    if (expandedGroups.includes(index)) {
      setExpandedGroups(expandedGroups.filter((i) => i !== index));
    } else {
      setExpandedGroups([...expandedGroups, index]);
    }
  };

  const toggleRelationship = (index) => {
    if (expandedRelationships.includes(index)) {
      setExpandedRelationships(expandedRelationships.filter((i) => i !== index));
    } else {
      setExpandedRelationships([...expandedRelationships, index]);
    }
  };

  const handleNodeGroupSelect = (e) => {
    const selectedGroupName = e.target.value;
    const selectedGroup = allGroups.find(group => group.group_name === selectedGroupName);
    setSelectedGroup(selectedGroup);

    const initialNodeData = {};
    (selectedGroup?.data_schema || []).forEach((field) => {
      initialNodeData[field.name] = '';
    });
    setNewNode(initialNodeData);
  };

  const handleUniqueToggle = () => {
    setNewRelationship({ ...newRelationship, unique: !newRelationship.unique });
  };

  return (
    <div className="configurator-page-container">
      <Header />

      <div className="configurator-page-layout">
        <Menu searchTerm={searchTerm} handleSearch={handleSearch} /> {/* Adding the Menu component */}

        <div className="configurator-content-wrapper">
          {/* Existing content starts here */}
          <div className="expandable-container">
            <h1>Overview</h1>

            {/* Groups Section */}
            <div className="expandable-section" onClick={toggleGroupsExpand}>
              <span>Groups ({allGroups.length})</span>
              <span className={`arrow ${isGroupsExpanded ? 'expanded' : ''}`}>↓</span>
            </div>
            {isGroupsExpanded && (
              <div className="expandable-content">
                {allGroups.map((group, index) => (
                  <div key={index}>
                    <div className="expandable-group" onClick={() => toggleGroup(index)}>
                      <span>{group.group_name} ({group.group_relationships.length})</span>
                      <span className={`arrow ${expandedGroups.includes(index) ? 'expanded' : ''}`}>↓</span>
                    </div>
                    {expandedGroups.includes(index) && (
                      <div>
                        <h3>Relationships:</h3>
                        <ul className="relationship-list">
                          {group.group_relationships.length > 0 ? (
                            group.group_relationships.map((relationship, relIndex) => (
                              <li key={relIndex}>
                                <strong>{relationship.name}</strong> ({relationship.type}) - From: {relationship.from_group} To: {relationship.to_group}
                              </li>
                            ))
                          ) : (
                            <li>No relationships found</li>
                          )}
                        </ul>
                        <h3>Data Schema:</h3>
                        <ul className="schema-list">
                          {group.data_schema && group.data_schema.length > 0 ? (
                            group.data_schema.map((schema, schemaIndex) => (
                              <li key={schemaIndex}>
                                {schema.name} - {schema.data_type} (Read-only: {schema.read_only ? 'Yes' : 'No'})
                              </li>
                            ))
                          ) : (
                            <li>No data schema defined</li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* Relationships Section */}
            <div className="expandable-section" onClick={toggleRelationshipsExpand}>
              <span>Relationships ({allRelationships.length})</span>
              <span className={`arrow ${isRelationshipsExpanded ? 'expanded' : ''}`}>↓</span>
            </div>
            {isRelationshipsExpanded && (
              <div className="expandable-content">
                {allRelationships.map((relationship, index) => (
                  <div key={index}>
                    <div className="expandable-group" onClick={() => toggleRelationship(index)}>
                      <span>{relationship.relationship_name}</span>
                      <span className={`arrow ${expandedRelationships.includes(index) ? 'expanded' : ''}`}>↓</span>
                    </div>
                    {expandedRelationships.includes(index) && (
                      <div>
                        <h3>From Groups:</h3>
                        <ul>
                          {relationship.from_groups.map((fromGroup, fromIndex) => (
                            <li key={fromIndex}>{fromGroup}</li>
                          ))}
                        </ul>
                        <h3>To Groups:</h3>
                        <ul>
                          {relationship.to_groups.map((toGroup, toIndex) => (
                            <li key={toIndex}>{toGroup}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Create Node */}
          <div className="configurator-box">
            <h1>Create Node</h1>
            {Array.isArray(allGroups) && allGroups.length > 0 ? (
              <>
                <div className="form-group">
                  <label>Select Group for Node</label>
                  <select onChange={handleNodeGroupSelect} className="dropdown">
                    <option value="">Select a group</option>
                    {allGroups.map((group, index) => (
                      <option key={index} value={group.group_name}>
                        {group.group_name}
                      </option>
                    ))}
                  </select>
                </div>

                {selectedGroup && (
                  <div className="form-group">
                    {(selectedGroup.data_schema || []).map((field, index) => (
                      <div key={index} className="input-row">
                        <label className="input-label">{field.name} ({field.data_type})</label>
                        <input
                          type={field.data_type === 'number' ? 'text' : 'text'}
                          value={newNode[field.name] || ''}
                          onChange={(e) => handleNodeDataChange(e, field.name)}
                          onKeyDown={field.data_type === 'number' ? handleNumberInputKeyDown : undefined}
                          placeholder={field.read_only ? `Created by Hubbiot` : `Enter ${field.name}`}
                          className={`input-field ${field.read_only ? 'gray-input' : ''}`}
                          disabled={field.read_only}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <p>No groups available</p>
            )}
            <button onClick={createNode}>Create Node</button>
            <p>{nodeMessage}</p>

            {/* QR Code generation section */}
            {qrCodeData && (
              <div className="qr-code-section">
                <div ref={qrCanvasRef}>
                  <QRCodeCanvas value={JSON.stringify(qrCodeData)} size={200} />
                </div>
                <button onClick={downloadQRCode} className="download-button">Download QR Code</button>
              </div>
            )}
          </div>

        {/* Create Group */}
        <div className="configurator-box">
          <h1>Create Node Group</h1>
          <div className="form-group">
            <label>Group Name</label>
            <input
              type="text"
              value={newGroup.group_name}
              onChange={(e) => setNewGroup({ ...newGroup, group_name: e.target.value })}
              placeholder="Enter group name"
            />
          </div>

          <h3>Data Schema:</h3>

          {/* Render schema fields */}
          {newGroup.data_schema.map((field, index) => (
            <div key={index} className="schema-row">
              <input
                type="text"
                value={field.name}
                onChange={(e) => handleSchemaChange(index, 'name', e.target.value)}
                placeholder="Attribute Name"
                className={`small-input ${field.read_only ? 'gray-input' : ''}`} // Gray out the default fields
                disabled={field.read_only}  // Disable editing for default fields
              />
              <select
                value={field.data_type}
                onChange={(e) => handleSchemaChange(index, 'data_type', e.target.value)}
                className="large-input"
                disabled={field.read_only}  // Disable editing for default fields
              >
                <option value="string">String</option>
                <option value="number">Number</option>
              </select>

              {!field.read_only && (  // Show delete button only for non-default fields
                <button
                  className="remove-button"
                  onClick={() => removeSchemaField(index)}
                >
                  Delete
                </button>
              )}
            </div>
          ))}

          {/* Button to add new schema fields */}
          <button onClick={addSchemaField} className="add-button">
            + Add Attribute
          </button>

          <div className="button-group centered">
            <button onClick={createGroup} className="create-button">Create Group</button>
          </div>
          <p>{groupMessage}</p>
        </div>

        {/* Create Relationship Type*/}
        <div className="configurator-box">
          <h1>Create Relationship Type</h1>
          <div className="form-group">
            <label>Relationship Type</label>
            <input
              type="text"
              value={newRelationship.relationship_type}
              onChange={(e) => setNewRelationship({ ...newRelationship, relationship_type: e.target.value })}
              placeholder="Enter relationship type"
            />
          </div>

          <div className="form-group">
            <label>From Group</label>
            <select onChange={(e) => setNewRelationship({ ...newRelationship, from_group: e.target.value })} className="dropdown">
              <option value="">Select a group</option>
              {allGroups.map((group, index) => (
                <option key={index} value={group.group_name}>
                  {group.group_name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>To Group</label>
            <select onChange={(e) => setNewRelationship({ ...newRelationship, to_group: e.target.value })} className="dropdown">
              <option value="">Select a group</option>
              {allGroups.map((group, index) => (
                <option key={index} value={group.group_name}>
                  {group.group_name}
                </option>
              ))}
            </select>
          </div>

          {/* Add Unique Checkbox */}
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={newRelationship.unique}
                onChange={handleUniqueToggle}
              />
              Unique Relationship (Allow only one edge of this type from a node)
            </label>
          </div>

          <div className="button-group centered">
            <button onClick={createRelationshipType} className="create-button">Create Relationship Type</button>
          </div>
          <p>{relationshipTypeMessage}</p>
        </div>

        {/* Create Relationship Section */}
        <div className="configurator-box">
          <h1>Create Relationship</h1>
          <div className="form-group">
            <label>Select Relationship Type</label>
            <select onChange={(e) => handleRelationshipSelection(e.target.value)} className="dropdown">
              <option value="">Select a relationship</option>
              {allRelationships.map((relationship, index) => (
                <option key={index} value={relationship.relationship_name}>
                  {relationship.relationship_name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>From Node</label>
            <select onChange={(e) => setSelectedFromNode(e.target.value)} className="dropdown">
              <option value="">Select a node</option>
              {fromNodes.map((node, index) => (
                <option key={index} value={node._id}>
                  {JSON.stringify(node)}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>To Node</label>
            <select onChange={(e) => setSelectedToNode(e.target.value)} className="dropdown">
              <option value="">Select a node</option>
              {toNodes.map((node, index) => (
                <option key={index} value={node._id}>
                  {JSON.stringify(node)}
                </option>
              ))}
            </select>
          </div>

          <div className="button-group centered">
            <button onClick={createRelationship} className="create-button">Create Relationship</button>
          </div>
          <p>{relationshipMessage}</p>
        </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default GraphConfiguratorPage;
