import { useState } from 'react';
import { useAuth } from '../../context/authContext'; // Ensure the correct path
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useRouter
import './SignInPage.css'; // Import the CSS

const SignInPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { signIn } = useAuth();
  const navigate = useNavigate(); // useNavigate for redirection

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await signIn(username, password);
      if (success) {
        navigate('/'); // Redirect to the home page after successful sign-in
      }
    } catch (err) {
      setError('Invalid username or password');
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-box">
        <h2 className="signin-title">Sign In</h2>
        <form onSubmit={handleSubmit} className="signin-form">
          <div className="signin-field">
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="signin-input"
              required
            />
          </div>
          <div className="signin-field">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="signin-input"
              required
            />
          </div>
          {error && <p className="signin-error">{error}</p>}
          <button type="submit" className="signin-button">Sign In</button>
        </form>
      </div>
    </div>
  );
};

export default SignInPage;
