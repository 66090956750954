import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './AuthPage.css';


const AuthPage = () => {
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    full_name: '',
    password: ''
  });
  
  // Use environment variable directly
  const defaultBaseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
  const [baseUrl, setBaseUrl] = useState(defaultBaseUrl);
  useEffect(() => {
    // Since the environment variable is injected at build time, we don't need to fetch it
    setBaseUrl(process.env.REACT_APP_BASE_URL || defaultBaseUrl);
  }, []);
  
  // Use the baseUrl in the API URL
  const createUserEndpoint = `${baseUrl}/create-user/`;

  const [message, setMessage] = useState('');

  // Function to call the backend API to create a new user
  const createUser = async () => {
    try {
      const response = await fetch(createUserEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username: newUser.username,
          email: newUser.email,
          full_name: newUser.full_name,
          password: newUser.password
        })
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage(data.detail || "Error creating user");
      }
    } catch (error) {
      setMessage("Error: " + error.message);
    }
  };

  return (
    <div className="auth-page-container">
      <Header />
      <div className="auth-content">
        <div className="auth-content-container">
          <h1>Create New User</h1>
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              value={newUser.username}
              onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Full Name</label>
            <input
              type="text"
              value={newUser.full_name}
              onChange={(e) => setNewUser({ ...newUser, full_name: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            />
          </div>
          <button onClick={createUser}>Create User</button>
          <p>{message}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AuthPage;
