import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import logo from './img/logo.svg'; // Importing the logo
import avatar from './img/avatar.svg'; // Importing the avatar image
import { useAuth } from '../context/authContext';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { user, loading, signOut } = useAuth(); // Get user, loading state, and signOut from authContext
  const navigate = useNavigate(); // Updated to useNavigate

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // If the auth state is loading, return nothing or a loading indicator
  if (loading) {
    return <div>Loading...</div>; // You can show a spinner or some other loading indicator
  }

  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>

      {/* Avatar button instead of the hamburger */}
      <div className="avatar-container" onClick={toggleMenu}>
        <img src={avatar} alt="User Avatar" className="avatar" />
      </div>

      {/* Navigation menu triggered by avatar click */}
      <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/">Home</Link></li>
          {user && (
            <>
              <li>
                <button onClick={signOut}>Sign Out</button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
