import React, { useRef, useEffect, Suspense, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './ArPage.css';
import industrialMachineModel from './models/robot_arm.glb';
import GraphOverlay from './GraphOverlay';
import miserablesData from './miserables';  // Import the dataset directly

// Component to load and render the industrial machine model
function IndustrialMachineModel(props) {
  const { scene } = useGLTF(industrialMachineModel);
  return <primitive object={scene} {...props} />;
}

const ArPage = () => {
  const containerRef = useRef();
  const graphContainerRef = useRef();
  const [graphData, setGraphData] = useState(miserablesData); // Set initial graph data
  
 // WebGL support check
 useEffect(() => {
  if (!window.WebGLRenderingContext) {
    console.error("WebGL not supported on this device.");
    alert("WebGL not supported on this device. Please try on a device that supports WebGL.");
  } else {
    console.log("WebGL is supported!");
  }
}, []);

  useEffect(() => {
    // Set container height and aspect ratio
    const setContainerHeight = () => {
      const arContainer = containerRef.current;
      if (arContainer) {
        const width = arContainer.offsetWidth || 500; // Fallback to default if width is zero
        const isMobile = window.innerWidth <= 768;
        const aspectRatio = isMobile ? 4 / 3 : 9 / 16; // 4:3 for mobile, 16:9 for larger screens
        const height = width * aspectRatio || 500; // Fallback to default if height is invalid
    
        if (width > 0 && height > 0) {
          arContainer.style.height = `${height}px`;
        }
      }
    };
    

    // Adjust container size on window resize
    setContainerHeight();
    window.addEventListener('resize', setContainerHeight);

    // Set dynamic framerate based on device
    const isMobile = window.innerWidth <= 768;
    const constraints = {
      video: {
        facingMode: 'environment',
        frameRate: isMobile ? { ideal: 30, max: 30 } : { ideal: 60, max: 60 },
      },
    };

    // Accessing the device's camera
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          const video = document.createElement('video');
          video.srcObject = stream;
          video.play();

          const arContainer = containerRef.current;
          arContainer.style.position = 'relative';
          arContainer.style.overflow = 'hidden';
          arContainer.appendChild(video);

          video.style.position = 'absolute';
          video.style.top = 0;
          video.style.left = 0;
          video.style.width = '100%';
          video.style.height = '100%';
          video.style.objectFit = 'cover';
          video.style.zIndex = 0;
          video.classList.add('video-feed');  // Add class for CSS handling
        })
        .catch((error) => {
          console.error('Error accessing the camera', error);
        });
    } else {
      console.error('getUserMedia is not supported on this browser!');
    }

    return () => {
      window.removeEventListener('resize', setContainerHeight);
    };
  }, []);

  return (
    <div className="ar-page-container">
      <Header />
      <div id="ar-container" ref={containerRef} className="ar-box">
        {/* Graph3D Overlay */}
        <div ref={graphContainerRef} className="graph-overlay" >
          <GraphOverlay data={graphData} containerRef={containerRef} />
        </div>
        {/* 3D AR canvas overlay */}
        <div ref={graphContainerRef} className="model-overlay">
        <Canvas className="ar-canvas" style={{ width: '100%', height: '100%' }}>
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} />
          <Suspense fallback={null}>
            <IndustrialMachineModel scale={[1, 1, 1]} position={[0, 0, -3]} />
          </Suspense>
          <OrbitControls />
        </Canvas>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ArPage;
