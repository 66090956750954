import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

// Import the context and components
import { AuthProvider, useAuth } from './context/authContext';
import AppLayout from './components/AppLayout'; // Use AppLayout for routes needing header

// Import the pages
import GraphPage from './pages/GraphPage/GraphPage';
import GenAIPage from './pages/GenAIPage/GenAIPage';
import AuthPage from './pages/AuthPage/AuthPage';
import HomePage from './pages/HomePage/HomePage';
import ApiPage from './pages/ApiPage/ApiPage';
import ArPage from './pages/ArPage/ArPage';
import GraphConfiguratorPage from './pages/GraphConfiguratorPage/GraphConfiguratorPage';
import SignInPage from './pages/SignInPage/SignInPage';

// PrivateRoute component to protect the routes
const PrivateRoute = ({ element }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Show a loading spinner while checking auth
  }

  return user ? element : <SignInPage />; // If authenticated, return the element, otherwise show the SignInPage
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes like SignInPage don't need AppLayout */}
          <Route path="/signin" element={<SignInPage />} /> 

          {/* Protected routes wrapped with AppLayout */}
          <Route path="/" element={<AppLayout><PrivateRoute element={<HomePage />} /></AppLayout>} />
          <Route path="/graph" element={<AppLayout><PrivateRoute element={<GraphPage />} /></AppLayout>} />
          <Route path="/genai" element={<AppLayout><PrivateRoute element={<GenAIPage />} /></AppLayout>} />
          <Route path="/api" element={<AppLayout><PrivateRoute element={<ApiPage />} /></AppLayout>} />
          <Route path="/ar" element={<AppLayout><PrivateRoute element={<ArPage />} /></AppLayout>} />
          <Route path="/configurator" element={<AppLayout><PrivateRoute element={<GraphConfiguratorPage />} /></AppLayout>} />
          <Route path="/auth" element={<AppLayout><AuthPage /></AppLayout>} /> {/* Public AuthPage */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
