// eslint-disable-next-line
import React from 'react';
import Header from '../../components/Header'; // Importing Header component
import Footer from '../../components/Footer'; // Importing Footer component
import './ApiPage.css';
import { RedocStandalone } from 'redoc';
import openApiSpec from './openapi.json'; // Import the OpenAPI specification

const ApiPage = () => {
  return (
    <div className="api-page-container">
      <Header /> {/* Include Header */}
      <div className="api-content">
        <div className="api-content-container">   
          <RedocStandalone 
            spec={openApiSpec}  // Use the imported OpenAPI specification
            options={{
              theme: { colors: { primary: { main: '#079fac' } } },
              scrollYOffset: 60, // Adjust the scroll offset if needed
            }}/>
        </div>
      </div>
      <Footer /> {/* Include Footer */}
    </div>
  );
};


export default ApiPage;